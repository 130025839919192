(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/frame-blur/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/tipsen/frame-blur/assets/javascripts/constants.js');
"use strict";

const framePosition = {
  START: 'START',
  END: 'END'
};
setGlobal('svs.components.tipsen.frameBlur.constants', {
  framePosition
});

 })(window);