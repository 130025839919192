(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/frame-blur/assets/javascripts/use-is-at-frame-position.js') >= 0) return;  svs.modules.push('/components/tipsen/frame-blur/assets/javascripts/use-is-at-frame-position.js');
"use strict";

const {
  useState,
  useEffect
} = React;
const {
  framePosition
} = svs.components.tipsen.frameBlur.constants;
const positionValidation = (_scrollElement, _position) => {
  if (_position === framePosition.START && _scrollElement.scrollLeft === 0) {
    return true;
  } else if (_position === framePosition.END && _scrollElement.scrollLeft === _scrollElement.scrollWidth - _scrollElement.offsetWidth) {
    return true;
  }
  return false;
};
const useIsAtFramePosition = (scrollElement, position) => {
  const [isAtPosition, setIsAtPosition] = useState(false);
  useEffect(() => {
    const onScrollRef = () => {
      window.requestAnimationFrame(() => {
        setIsAtPosition(positionValidation(scrollElement, position));
      });
    };
    if (scrollElement) {
      setIsAtPosition(positionValidation(scrollElement, position));
      scrollElement.addEventListener('scroll', onScrollRef);
    }
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', onScrollRef);
      }
    };
  }, [scrollElement, position]);
  return isAtPosition;
};
setGlobal('svs.components.tipsen.frameBlur.useIsAtFramePosition', useIsAtFramePosition);

 })(window);