(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/frame-blur/assets/javascripts/frame-blur.js') >= 0) return;  svs.modules.push('/components/tipsen/frame-blur/assets/javascripts/frame-blur.js');
"use strict";

const {
  useIsAtFramePosition
} = svs.components.tipsen.frameBlur;
const FrameBlur = _ref => {
  let {
    scrollElement,
    position,
    className
  } = _ref;
  const isAtMyPosition = useIsAtFramePosition(scrollElement, position);
  const prefixedPositionClass = className ? "".concat(className, "-").concat(position.toLowerCase()) : '';
  return React.createElement("div", {
    className: "frame-blur ".concat(className ? "".concat(className, " ") : '', "frame-blur-").concat(position.toLowerCase(), " ").concat(isAtMyPosition ? 'frame-blur-hide' : '', " ").concat(prefixedPositionClass)
  });
};
setGlobal('svs.components.tipsen.frameBlur.FrameBlur', FrameBlur);

 })(window);